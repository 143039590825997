/*
 * DET 29 April 2020 - this is a first pass at having some way to map the images as
 * specific ones change in the project - the key is our well known identifier
 * (we'd use an enum in Python) and the value is the base name
 * All images are assumed to be in the same place, and that the gatsby-source-filesystem
 * defines a relative path for that.
 *
 * Haven't seen any enum solution I like so using strings as keys
 */
const imageIdentifierMap = new Map([
  ["CONTACT-US", "contact-us-4193401_1920.jpg"],
  ["HEADSHOT-THOMPSON-1", "thompson-headshot-1-200x300.png"],
  ["HEADSHOT-THOMPSON-2", "thompson-headshot-2.jpg"],
  ["LOGO-3LEAPS", "3-Leaps-Horizontal-Color-72ppi"],
  ["CARD-HEADLINE", "card-volatility-candlestick-over-world.jpg"],
  ["CARD-HERO-MAIN", "iStock-1280582431-baseline-website-hero-teal-small-72ppi.jpg"],
  ["CARD-HERO-MAIN-TAGLINE", "iStock-1280582431-baseline-website-hero-teal-small-with-tagline-72ppi.jpg"],
  ["CARD-VOLATILITY", "card-volatility-candlestick-763982227_Nokwan007.jpg"],
  ["CARD-HISTORY-NOT-SAME", "card-history-doubtful-uncertainty_2125x1416.jpeg"],
  ["CARD-INNOVATION-OVERHAUL-2", "card-innovation-overhaul-wall-demolition_1506416447.jpg"],
  ["CARD-INNOVATION-OVERHAUL", "card-innovation-overhaul-wall-demolition.jpg"],
  ["CARD-TRUST-MADE-KEPT", "card-trust-man-delivering-cardboard+2122x1415.jpeg"],
  ["CARD-OPPORTUNITY-JUMPING", "card-opportunity-goldfish-jumping2.jpg"],
  ["CONCEPT-DECISION-MAKING-GROUP", "concept-group-decision-making-flipchart_1645093387.jpg"],
  ["CONCEPT-CRAWL-WALK-RUN", "concept-group-decision-making-flipchart_1645093387.jpg"],
  ["CONCEPT-CLEAN-LAUNDRY", "picture-linens-bleach_Lana-Langlois-stock-dot-adobe-dot-com_297805464_1200x851.png"],
  ["ACTION-MAKING-LEMONADE", "action-making-lemonade_724659223.jpg"],
  ["DIAGRAM-DECISION-CONSOLE-EXAMPLE", "decision-console-example.jpg"],
])

export default imageIdentifierMap