import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import imageIdentifierMap from "./imageIdentifierMap"

/*
 *  Remember that you cannot change graphql queries with react parameters
 *  because they are built ahead of loading.  You can make multiple queries
 *  however, and pick the ones you want based on attributes
 *  For example you could add grayscale and have one set with color and one without
 *  Take a property like grayscale - but remember the allFile can't have two
 *  of same or the build process will create two of same name
 *
 *  Probably we are not doing this right but returning an array so we can get the source path - needed sometimes
 *  for background images
 */
const theGraphSL = graphql`
  query {
    STANDARD: allFile(filter: {internal: {mediaType: {regex: "/image/"}}, sourceInstanceName: {eq:"images"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
              originalName
            }
          }
        }
      }
    },
    GRAYSCALE: allFile(filter: {internal: {mediaType: {regex: "/image/"}}, sourceInstanceName: {eq:"images"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 900, quality: 100, grayscale: true) {
              ...GatsbyImageSharpFluid
              presentationWidth
              originalName
            }
          }
        }
      }
    }
  }
 `

const ImageSharpData = (props) => {
  const allImageData = useStaticQuery(theGraphSL
  )


  /*const BackgroundImageStyledOpacity = styled(BackgroundImage)`
    &::before,
    &::after {
      filter: opacity(
        ${({ opacity }) => {
  return opacity
}}
      );
    }
    `

   *  Get the image name from the caller props.  Rules:
   * 1) If name provided, use it
   * 2) If identifier provided, use it in the name map
   * Return null if no image name found (later we will also return null
   * if we can't find the image by name)
   */
  const imageNameToFind =
    (props.imageName && props.imageName.length > 0) ?
      props.imageName :
      ((props.imageIdentifier != null && props.imageIdentifier.length > 0) ?
        imageIdentifierMap.get(props.imageIdentifier) :
        null)
  if (!imageNameToFind) {
    // if we didn't specify a parameter, assume we wanted all or a subset
    if ((!props.imageName || props.imageName.length === 0) &&
      (!props.imageIdentifier || props.imageIdentifier.length === 0)) {
      console.log("No filter specified by imageName or imageIdentifier - returning all images")
    } else {
      console.log("Unable to retrieve image based on imageIdentifier = \"" +
        props.imageIdentifier + "\" and imageName = \"" + props.imageName + "\"")
      return null
    }
  } else {
    if (props.debugConsole) {
      console.log("Retrieving image " + imageNameToFind + " on catalog " + props.catalog)
    }
  }

  /*
   * Now query all images (remember the gatsby-source-filesystem set the path)
   * We may improve this by parameterizing the query as a string and using
   * in multiple instances - remember you can only have one static query
   * in a file, so if you want to build a catalog of images with certain effect,
   * it has to be in a different place - think abstract class with one for
   * regular, one for grayscale, etc. - an imperfect analogy
   *
   * https://stackoverflow.com/questions/51349801/querying-all-images-in-folder-using-graphql
   * Contains a good idea on how to use a source instance name to get a subdirectory of images
   * we could put all the story card images in one place as example
   *
   * Useful log
   *   console.log(`images:${JSON.stringify(images,null,2)} and pos 0=>${JSON.stringify(images[0].node.childImageSharp.fluid,null,2)}`);
   *
   * Other notes
   * 1) name property does not include extension
   * 2) originalName in fluid element does include extension
   *
   * I don't know how to parameterize the imageSharpFound (ie const imageSharpFound = `data.${props.catalog}.` - it works in strings
   */
  const imageSharpFoundGreyscale = allImageData.GRAYSCALE.edges.filter(img => img.node.childImageSharp.fluid.originalName === imageNameToFind)
  const imageSharpFoundStandard = allImageData.STANDARD.edges.filter(img => img.node.childImageSharp.fluid.originalName === imageNameToFind)

  const imageSharpFound =
    (props.catalog.toUpperCase() === "GREYSCALE" || props.catalog.toUpperCase() === "GRAYSCALE") ?
      imageSharpFoundGreyscale :
      imageSharpFoundStandard

  if (props.debugConsole) {
    console.log("====================================")
    console.log(`images:${JSON.stringify(imageSharpFound, null, 2)} and pos 0=>${JSON.stringify(imageSharpFound[0].node.childImageSharp.fluid, null, 2)}`)
    console.log("====================================")
  }
  return (
    imageSharpFound
  )
}

ImageSharpData.propTypes = {
  imageName: PropTypes.string,
  imageIdentifier: PropTypes.string,
  alt: PropTypes.string,
  catalog: PropTypes.string,
  debugConsole: PropTypes.bool
}

ImageSharpData.defaultProps = {
  imageName: "",
  imageIdentifier: "",
  alt: "",
  catalog: "STANDARD",
  debugConsole: false
}

export default ImageSharpData
