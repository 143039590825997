import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import ImageSharpData from "./imageSharpData"

/*
 *  This is a wrapper to ImageSharpData that returns the Img div we would need
 *  when adding into layout elements directly
 */
const ImageSharpImage = (props) => {
  const imageSharp = ImageSharpData(props);
  // this would expect a length of one if either imageName or imageIdentifier specified
  if (props.debugConsole) {
    console.log('ImageSharpImage Found length of image = ' + imageSharp.length);
  }
  if (!imageSharp) {
    console.log('Unable to retrieve image sharp');
    return null;
  }

  // DET - do not think the image style is doing anything when in a Box
  const imageStyleModifier = props.imgStyle ? ("imgStyle=" + props.imgStyle) : "";

  return (
    <Img
      fluid={imageSharp[0].node.childImageSharp.fluid}
      alt={props.alt ? props.alt : imageSharp[0].node.name}
      {...imageStyleModifier}
    />
    );
}

ImageSharpImage.propTypes = ImageSharpData.propTypes

ImageSharpImage.defaultProps = ImageSharpData.defaultProps

export default ImageSharpImage